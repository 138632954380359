@import '../variables.scss';

.textImageWrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  padding-bottom: 6.024vw;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.8s ease;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-md) {
    width: 90%;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: $breakpoint-lg) {
    max-width: 1660px;
  }
  &.visible {
    opacity: 1;
    &.reverse {
      .image {
        transform: scale(1);
      }
      .content {
        transform: translateY(0);
      }
    }
    .image {
      transform: scale(1);
    }
    .content {
      transform: translateY(0);
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }
    .image {
      display: flex;
      justify-content: flex-end;
      padding: 0 0 0 2.3vw;
      transition: transform 0.8s ease;
      transform: scale(0.8);
      @media (max-width: $breakpoint-md) {
        justify-content: center;
        padding-left: 0;
      }
    }
    .content {
      padding-left: 0;
      transition: transform 0.8s ease;
      transform: translateY(30px);
    }
  }
  .image {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    padding: 0 2.3vw 0 0;
    transition: transform 0.8s ease;
    transform: scale(0.8);
    @media (max-width: $breakpoint-md) {
      width: 100%;
      justify-content: center;
      padding-right: 0;
    }
    img {
      width: 100%;
    }
  }
  .content {
    width: 50%;
    padding: 0 1.807vw 0 2.3vw;
    transition: transform 0.8s ease;
    transform: translateY(20px);
    @media (max-width: $breakpoint-md) {
      width: 100%;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      position: relative;
      font-size: 2.53vw;
      line-height: 2.41vw;
      margin: 0;
      padding-bottom: 1.747vw;
      @media (max-width: $breakpoint-md) {
        font-size: 28px;
        line-height: 36px;
        padding-bottom: 25px;
      }
      @media (min-width: $breakpoint-lg) {
        font-size: 42px;
        line-height: 40px;
        padding-bottom: 29px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        background: $red;
        width: 3.494vw;
        height: 0.361vw;
        border-radius: 0.181vw;
        left: 0;
        @media (max-width: $breakpoint-md) {
          border-radius: 3px;
          width: 40px;
          height: 4px;
          left: 50%;
          transform: translateX(-50%);
        }
        @media (min-width: $breakpoint-lg) {
          border-radius: 3px;
          width: 58px;
          height: 6px;
        }
      }
    }
    .text {
      padding-bottom: 2.711vw;
      max-width: 26.566vw;
      @include FONT-REGULAR;
      @media (max-width: $breakpoint-md) {
        text-align: center;
        max-width: 441px;
        padding-bottom: 45px;
      }
      @media (min-width: $breakpoint-lg) {
        padding-bottom: 45px;
      }
    }
    .button {
      padding: 1.205vw 3.193vw;
      @media (min-width: $breakpoint-lg) {
        padding: 20px 53px;
      }
    }
  }
  .text:nth-child(2) {
    padding-top: 2.711vw;
    @media (max-width: $breakpoint-md) {
      padding-top: 45px;
    }
    @media (min-width: $breakpoint-lg) {
      padding-top: 45px;
    }
  }
}
