@import '../variables.scss';

.columnsWrapper {
  position: relative;
  padding: 4.217vw 3.855vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-md) {
    padding: 42px 0 0 0;
  }
  &.visible {
    opacity: 1;
    .columsContentWrapper {
      transform: translateY(0);
    }
  }
  &.secondDefault {
    .columsContentWrapper {
      p {
        max-width: 46vw;
        @media (max-width: $breakpoint-md) {
          max-width: 65%;
        }
      }
    }
  }
  .columsContentWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 0.301vw;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.11);
    padding: 5vw 0;
    transition: transform 0.8s ease;
    transform: translateY(30px);
    @media (max-width: $breakpoint-md) {
      width: 100%;
      box-shadow: none;
    }
    @media (min-width: $breakpoint-lg) {
      max-width: 1660px;
      padding: 80px 0;
      margin: auto;
    }
    h2 {
      margin: 0 auto 0 auto;
      position: relative;
      padding-bottom: 1.687vw;
      text-align: center;
      @media (max-width: $breakpoint-md) {
        padding-bottom: 25px;
      }
      @media (min-width: $breakpoint-lg) {
        padding-bottom: 31px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        background: $red;
        width: 3.494vw;
        height: 0.361vw;
        border-radius: 0.181vw;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: $breakpoint-md) {
          border-radius: 3px;
          width: 40px;
          height: 4px;
        }
        @media (min-width: $breakpoint-lg) {
          border-radius: 3px;
          width: 58px;
          height: 6px;
        }
      }
    }
  }
  p {
    text-align: center;
    max-width: 34.699vw;
    margin: 1.807vw auto 3.072vw auto;
    @media (max-width: $breakpoint-md) {
      margin: 30px auto 43px auto;
      max-width: 70%;
    }
    @media (min-width: $breakpoint-lg) {
      margin: 30px auto 50px auto;
    }
  }
  .columnWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 86%;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }
    .column {
      width: 33%;
      text-align: center;
      transition: transform 0.8s ease;
      transform: translateY(30px);
      opacity: 0;
      &:nth-child(2) {
        transition: all 0.8s ease 0.1s;
        @media (max-width: $breakpoint-md) {
          padding-top: 45px;
        }
      }
      &:nth-child(3) {
        transition: all 0.8s ease 0.3s;
        @media (max-width: $breakpoint-md) {
          padding-top: 45px;
        }
      }
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
      &.visibleColumn {
        transform: translateY(0);
        opacity: 1;
      }
      img {
        padding-bottom: 0.663vw;
        @media (max-width: $breakpoint-md) {
          padding-bottom: 11px;
        }
        @media (min-width: $breakpoint-lg) {
          padding-bottom: 11px;
        }
      }
      p {
        font-family: $font-head-black;
        margin: auto;
        padding: 0.663vw;
        @media (max-width: $breakpoint-md) {
          margin: auto;
          padding: 11px 0;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 11px 0;
        }
        &:nth-child(2) {
          font-size: 2.229vw;
          line-height: 1.687vw;
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 28px;
          }
          @media (min-width: $breakpoint-lg) {
            font-size: 34px;
            line-height: 34px;
          }
        }
        &:nth-child(3) {
          font-family: $font-sans-bold;
          max-width: 24.096vw;
          @media (max-width: $breakpoint-md) {
            max-width: 85%;
          }
        }
      }
    }
  }
}
