@import '../variables.scss';

.textImageSliderWrapper {
  position: relative;
  background: $tabs-inactive;
  padding-bottom: 8.735vw;
  scroll-margin-top: 75px;
  .sliderWrapper {
    opacity: 0;
    transition: opacity 0.8s ease;
    &.visible {
      opacity: 1;
      .slide {
        .image {
          transform: scale(1);
        }
        .content {
          transform: translateY(0);
        }
      }
    }
  }

  &.secondDefault {
    :global(.slick-dots) {
      z-index: 3;
      @media (max-width: $breakpoint-md) {
        z-index: -3;
      }
    }
    .slide {
      overflow: hidden;
      .title {
        h2 {
          margin: 0 auto;
        }
      }
      .image {
        @media (max-width: $breakpoint-md) {
          width: 85%;
          margin: 25px auto;
        }
        img {
          width: 120%;
          transform: translateX(-20%);
          @media (max-width: $breakpoint-md) {
            width: 100%;
            transform: translateX(0);
          }
        }
      }
      .content {
        margin: 0 auto;
      }
      &.reverse {
        .image {
          img {
            width: 120%;
            transform: translateX(3%);
            @media (max-width: $breakpoint-md) {
              width: 100%;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
  :global(.slick-dots) {
    bottom: -4.217vw;
    z-index: 3;
    @media (max-width: $breakpoint-md) {
      bottom: auto;
      top: 42vw;
    }
    li {
      margin: 0 0.542vw;
      @media (max-width: $breakpoint-md) {
        margin: 0 1.5px;
      }
      ::before {
        content: '';
        width: 0.41vw;
        height: 0.41vw;
        color: $red;
        background: transparent;
        border: 0.181vw solid $red;
        border-radius: 50%;
        @media (max-width: $breakpoint-md) {
          width: 9px;
          height: 9px;
          border: 3px solid $red;
        }
      }
    }
    :global(.slick-active) {
      ::before {
        content: '';
        width: 0.41vw;
        height: 0.41vw;
        color: $red;
        background: $red;
        border-radius: 50%;
        @media (max-width: $breakpoint-md) {
          width: 9px;
          height: 9px;
        }
      }
    }
  }
  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media (min-width: $breakpoint-lg) {
      max-width: 1660px;
    }
    &.reverse {
      flex-direction: row-reverse;
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
      }
    }
    .image {
      transition: transform 0.8s ease;
      transform: scale(0.8);
      width: 50%;
      @media (max-width: $breakpoint-md) {
        margin: 0 auto;
        width: 85%;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .content {
      width: 50%;
      padding: 0 4.518vw;
      transition: transform 0.8s ease;
      transform: translateY(30px);
      @media (max-width: $breakpoint-md) {
        width: 90%;
        margin: 60px auto 20px auto;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 0 75.2px;
      }
      h2 {
        margin: 0 auto;
        @media (max-width: $breakpoint-md) {
          text-align: center;
        }
      }
      .subtitle {
        @include FONT-BOLD;
        margin: 1.928vw 0 0 0;
        @media (max-width: $breakpoint-md) {
          text-align: center;
          margin-top: 23px;
        }
        @media (min-width: $breakpoint-lg) {
          margin-top: 23px;
        }
      }
      .text {
        @include FONT-REGULAR;
        text-align: left;
        @media (max-width: $breakpoint-md) {
          text-align: center;
        }
      }
      .button {
        margin-top: 2.229vw;
        padding: 1.205vw 3.012vw;
        @media (max-width: $breakpoint-md) {
          margin-top: 30px;
          padding: 20px 50px;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 20px 50px;
          margin-top: 30px;
        }
      }
    }
  }
}
