@import '../variables.scss';

.headerWrapper {
  position: fixed;
  width: 100%;
  height: max-content;
  background: $white;
  z-index: 9999;
  box-shadow: 0 0 12px 3px $box-shadow;
  .headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.807vw;
    position: relative;
    @media (max-width: $breakpoint-md) {
      padding: 30px;
      justify-content: flex-end;
    }
    @media (min-width: $breakpoint-lg) {
      padding: 30px;
      max-width: 1660px;
      margin: auto;
    }
    @media (min-width: $breakpoint-lg) {
    }
  }
  .logo {
    position: absolute;
    left: 1.807vw;
    top: 50%;
    transform: translateY(-50%);
    width: 3.614vw;
    height: 2.169vw;
    @media (max-width: $breakpoint-md) {
      left: 30px;
      width: 60px;
      height: 36px;
    }
    @media (min-width: $breakpoint-lg) {
      left: 120px;
      width: 60px;
      height: 36px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: contain;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .menuWrapper {
    .burgerMenu {
      display: none;
      @media (max-width: $breakpoint-md) {
        display: flex;
        align-items: center;
        width: 24px;
        height: 17px;
        position: relative;
        span {
          height: 2px;
          width: 18px;
          background: $burger-menu;
          transition: all 0.3s ease;
          position: absolute;
          &:nth-child(1) {
            top: 1px;
          }
          &:nth-child(2) {
            top: 7px;
            width: 14px;
          }
          &:nth-child(3) {
            top: 14px;
          }
        }
      }
      &.burgerOpen {
        span {
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(1) {
            top: 6px;
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            top: 6px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    .menu {
      width: 100%;
      padding: 0;
      margin: 0;
      @media (max-width: $breakpoint-md) {
        gap: unset;
        background-color: $yellow;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0px;
        padding: 0;
        overflow: hidden;
        height: 0;
        transition: height 1s;
        z-index: 100;
      }
      &.openMenu {
        height: 100vh;
        overflow: scroll;
      }
      .burger_open {
        display: none;
        @media (max-width: $breakpoint-md) {
          display: flex;
          width: 100%;
          position: relative;
          height: 17px;
          width: 26px;
          transform: translate(87vw, 30px);
          opacity: 0;
          transition: opacity 0.8s;
        }
        img {
          width: 22px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 22px;
          padding: 3px;
        }
        &.showClose {
          display: flex;
          justify-content: flex-end;
          opacity: 1;
          z-index: 1;
          padding-right: 5px;
        }
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.735vw;
        z-index: 1;
        @media (max-width: $breakpoint-md) {
          flex-direction: column;
          align-items: center;
          padding-top: 75px;
        }
        li {
          @include FONT-BOLD;
          list-style-type: none;
          @media (max-width: $breakpoint-md) {
            @include FONT-REGULAR;
            padding: 4.15512vh 5.33333vw;
            color: $white;
            font-size: 21px;
            line-height: 31px;
          }
        }
        .contactUs {
          a {
            display: flex;
            align-items: center;
            .contactIcon {
              position: relative;
              @media (max-width: $breakpoint-md) {
                width: 28px;
                height: 28px;
                margin-right: 10px;
              }
              img {
                object-fit: contain;
              }
            }
            span {
              display: block;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .contact {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: $breakpoint-md) {
      right: 85px;
    }
    a {
      display: flex;
      align-items: center;
      .contactIcon {
        position: relative;
        width: 1.687vw;
        height: 1.687vw;
        margin-right: 1.506vw;
        @media (max-width: $breakpoint-md) {
          width: 28px;
          height: 28px;
          margin-right: 0;
        }
        @media (min-width: $breakpoint-lg) {
          width: 28px;
          height: 28px;
          margin-right: 20px;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      span {
        display: block;
        margin-left: 5px;
        color: $red;
        font-family: $font-sans-bold;
        font-size: 0.964vw;
        letter-spacing: 0px;
        line-height: 1.446vw;
        @media (min-width: $breakpoint-lg) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
}
