@import '../variables.scss';

.moduleTitleWrapper {
  position: relative;
  padding: 2.41vw 0;
  transition: opacity 0.8s ease;
  scroll-margin-top: 75px;
  @media (max-width: $breakpoint-md) {
    padding: 50px 0 25px 0;
  }
  @media (min-width: $breakpoint-lg) {
    padding: 77px 0 50px 0;
  }
  &.visible {
    .contentWrapper {
      p {
        transform: translateY(0);
      }
      .titleWrapper {
        h2 {
          transform: scale(1);
        }
      }
    }
  }
  &.secondDefault {
    .contentWrapper {
      .titleWrapper {
        h2 {
          max-width: 80%;
          @media (max-width: $breakpoint-md) {
            white-space: pre-line;
          }
        }
        .imgWrapper {
          max-height: fit-content;
          @media (min-width: $breakpoint-lg) {
            &:before {
              content: '';
              height: 92%;
              width: calc((100% - 1660px + 4px) / 2);
              position: absolute;
              left: 0;
              bottom: 50%;
              transform: translateY(50%);
              background: $title-variation-2;
              display: inline-block;
            }
            &:after {
              content: '';
              height: 92%;
              width: calc((100% - 1660px + 4px) / 2);
              position: absolute;
              right: 0;
              bottom: 50%;
              transform: translateY(50%);
              background: $title-variation-2;
              display: inline-block;
            }
          }
          img {
            transform: scale(1.04);
            @media (max-width: $breakpoint-md) {
              min-height: 78px;
              transform: scale(1);
            }
            @media (min-width: $breakpoint-lg) {
              transform: scale(1);
              width: 1660px;
            }
          }
        }
      }
    }
  }
  .contentWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    @media (max-width: $breakpoint-md) {
    }
    p {
      margin: 0;
      padding: 3.434vw 0;
      max-width: 36.699vw;
      margin: auto;
      transition: transform 0.8s ease;
      transform: translateY(30px);
      @media (max-width: $breakpoint-md) {
        padding: 57px;
        max-width: 100%;
      }
      @media (min-width: $breakpoint-lg) {
        padding: 57px 0 50px 0;
      }
    }
    .titleWrapper {
      position: relative;
      h2 {
        margin: 0 auto 0 auto;
        position: relative;
        white-space: normal;
        max-width: 49.88vw;
        transition: transform 0.8s ease;
        transform: scale(0);
        @media (max-width: $breakpoint-md) {
          white-space: pre-line;
          max-width: 70%;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          background: $red;
          width: 3.494vw;
          height: 0.361vw;
          border-radius: 0.181vw;
          left: 50%;
          bottom: -1.566vw;
          transform: translateX(-50%);
          @media (max-width: $breakpoint-md) {
            border-radius: 3px;
            width: 40px;
            height: 4px;
            bottom: -25px;
          }
          @media (min-width: $breakpoint-lg) {
            border-radius: 3px;
            width: 58px;
            height: 6px;
            bottom: -26px;
          }
        }
      }
      .imgWrapper {
        position: absolute;
        overflow: hidden;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        vertical-align: middle;
        max-height: 100%;
        img {
          width: 100%;
          object-fit: cover;
          transform: scale(1.05);
          vertical-align: middle;
          height: auto;
          max-height: 100%;
          @media (max-width: $breakpoint-md) {
            min-height: 39px;
          }
        }
      }
    }
  }
}
