@import '../variables.scss';

.tabNavigationWrapper {
  position: relative;
  opacity: 0;
  transition: opacity 0.8s ease;
  padding-bottom: 3.012vw;
  scroll-margin-top: 75px;
  &.visible {
    opacity: 1;
    .tabContentWrapper {
      .tabContent {
        .content {
          transform: translate(0);
        }
        .image {
          transform: scale(1);
        }
      }
    }
  }
  &.default {
    .tabNameWrapper {
      div {
        flex: 0 1 22%;
        @media (min-width: $breakpoint-lg) {
          flex: 0 1 450px;
        }
        .tabName {
          text-align: center;
          @media (max-width: $breakpoint-md) {
            text-align: left;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc((100% - 66%) / 2);
        height: 0.06vw;
        background: $tabs-borders;
        left: 0;
        z-index: -1;
        @media (max-width: $breakpoint-md) {
          display: none;
        }
        @media (min-width: $breakpoint-lg) {
          width: calc((100% - 1350px) / 2);
          height: 1px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc((100% - 66%) / 2);
        height: 0.06vw;
        background: $tabs-borders;
        right: 0;
        z-index: -1;
        @media (max-width: $breakpoint-md) {
          display: none;
        }
        @media (min-width: $breakpoint-lg) {
          width: calc((100% - 1350px) / 2);
          height: 1px;
        }
      }
    }
  }
  .tabNameWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 6.024vw;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      margin-bottom: 25px;
    }
    @media (min-width: $breakpoint-lg) {
      margin-bottom: 100px;
    }
    .tabName {
      cursor: pointer;
      border: 0.06vw solid $tabs-borders;
      padding: 0.723vw 1.048vw;
      border-radius: 0.301vw 0.301vw 0 0;
      background: $tabs-inactive;
      font-family: $font-head;
      font-size: 0.964vw;
      line-height: 1.265vw;
      @media (max-width: $breakpoint-md) {
        border: 1px solid $tabs-borders;
        padding: 16px 0 20px 52px;
        border-radius: 0;
        font-size: 16px;
        line-height: 21px;
        min-height: 57px;
        &:before {
          background-image: url('/assets/images/chevron.svg');
          height: 7px;
          width: 13px;
          content: '';
          display: inline-block;
          background-size: cover;
          margin: auto 16px 0 auto;
          transition: all 0.5s ease;
        }
      }
      @media (min-width: $breakpoint-lg) {
        font-size: 16px;
        line-height: 24px;
        padding: 20px 50px;
        text-align: center;
      }
      &.active {
        border-bottom: 0.06vw solid $white;
        background: $white;
        font-family: $font-head-black;
        @media (max-width: $breakpoint-md) {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .tabContentWrapper {
    margin: 0 6.024vw;
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 30px;
    }
    @media (min-width: $breakpoint-lg) {
      max-width: 1660px;
      margin: auto;
      padding: 0 101px;
    }
    .tabContent {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row-reverse;
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &.reverse {
        flex-direction: row;
        @media (max-width: $breakpoint-md) {
          flex-direction: column;
        }
      }
      .content {
        width: 50%;
        transform: translateY(30px);
        transition: transform 0.8s ease;
        @media (max-width: $breakpoint-md) {
          width: 100%;
          padding-bottom: 30px;
        }
        .headline {
          font-family: $font-sans-bold;
          font-size: 1.446vw;
          line-height: 1.687vw;
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 28px;
          }
        }
        .subline {
          @include FONT-REGULAR;
        }
        .text {
          @include FONT-REGULAR;
        }
      }
      .button {
        margin-top: 1.566vw;
        @media (max-width: $breakpoint-md) {
          margin: 24px auto;
        }
      }
      .downloadWrapper {
        position: relative;
        @media (max-width: $breakpoint-md) {
          text-align: left;
          margin-bottom: 15px;
          order: -1;
        }
        &:after {
          position: absolute;
          width: 100%;
          height: 0.12vw;
          background: $black;
          bottom: -0.904vw;
          content: '';
          @media (max-width: $breakpoint-md) {
            height: 2px;
            bottom: -15px;
            width: 95%;
          }
        }
      }
      .downloadBtn {
        font-family: $font-sans-bold;
        color: $red;
        font-size: 1.566vw;
        line-height: 1.446vw;
        width: 100%;
        margin-top: 2.711vw;
        cursor: pointer;
        @media (max-width: $breakpoint-md) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 35px;
        }
        @media (min-width: $breakpoint-lg) {
          font-size: 25px;
          line-height: 24px;
          margin-top: 45px;
        }
        &:after {
          background-image: url('/assets/images/chevron.svg');
          height: 0.783vw;
          width: 1.446vw;
          content: '';
          display: inline-block;
          background-size: cover;
          margin: auto auto 0.301vw 0.964vw;
          transition: all 0.5s ease;
          @media (max-width: $breakpoint-md) {
            height: 7px;
            width: 12px;
            margin: auto auto 5px 16px;
          }
          @media (min-width: $breakpoint-lg) {
            background-size: contain;
            height: 15px;
            width: 26px;
            margin: auto auto 0px 30px;
            background-repeat: no-repeat;
          }
        }
        &.transform {
          @media (max-width: $breakpoint-md) {
            margin-bottom: 20px;
          }
          &:after {
            transform: rotate(180deg);
          }
        }
      }
      .downloadsContainer {
        overflow: hidden;
        max-height: 0;
        transition: all 0.3s ease-out;
        &.show {
          transition: all 0.3s ease-out;
          margin-top: 1.205vw;
          @media (max-width: $breakpoint-md) {
            margin-top: 20px;
          }
        }
        .download {
          @include FONT-REGULAR;
          color: $red;
          a {
            position: relative;
            margin-left: 0.904vw;
            @media (max-width: $breakpoint-md) {
              margin-left: 15px;
            }
            &::before {
              content: '';
              width: 0.482vw;
              height: 0.482vw;
              background: $red;
              position: absolute;
              border-radius: 50px;
              top: 50%;
              transform: translateY(-50%);
              left: -0.904vw;
              @media (max-width: $breakpoint-md) {
                width: 8px;
                height: 8px;
                left: -15px;
                top: 5px;
                transform: translateY(0);
              }
              @media (min-width: $breakpoint-lg) {
                width: 8px;
                height: 8px;
              }
            }
          }
        }
      }
      .image {
        width: 45%;
        transition: transform 0.8s ease;
        transform: scale(0.8);
        @media (max-width: $breakpoint-md) {
          width: 90%;
          margin: auto;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  &.secondDefault {
    .tabNameWrapper {
      div {
        flex: 0 1 22%;
        @media (min-width: $breakpoint-lg) {
          flex: 0 0 332px;
        }
      }
      .tabName {
        padding: 0.723vw 1.048vw;
        text-align: center;
        @media (max-width: $breakpoint-md) {
          padding: 18px 23px;
          text-align: left;
        }
        @media (min-width: $breakpoint-lg) {
          padding: 18px 50px;
        }
      }
      &:before {
        display: none;
        @media (min-width: $breakpoint-lg) {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          width: calc((100% - 1660px) / 2);
          height: 0.06vw;
          background: $tabs-borders;
          left: 0;
          z-index: -1;
        }
      }
      &:after {
        display: none;
        @media (min-width: $breakpoint-lg) {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          width: calc((100% - 1660px) / 2);
          height: 1px;
          background: $tabs-borders;
          right: 0;
          z-index: -1;
        }
      }
    }
  }
  .tabContentWrapper {
    @media (max-width: $breakpoint-md) {
      margin: 0 43px;
    }
    .tabContent {
      width: 100%;
      display: block;
      .tabModule {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding-bottom: 5vw;
        width: 64.458vw;
        margin: auto;
        @media (min-width: ($breakpoint-lg + 1px)) {
          max-width: 1660px;
          justify-content: space-evenly;
        }
        @media (max-width: $breakpoint-md) {
          flex-direction: column-reverse;
          width: 100%;
        }
        &.reverse {
          flex-direction: row;
          @media (max-width: $breakpoint-md) {
            flex-direction: column-reverse;
          }
        }
        .content {
          width: 45%;
          @media (max-width: $breakpoint-md) {
            width: 100%;
            margin-top: 23px;
          }
        }
        .image {
          @media (max-width: $breakpoint-md) {
            width: 100%;
          }
          @media (min-width: $breakpoint-lg) {
            text-align: center;
          }
          video {
            width: 100%;
          }
        }
        h2 {
          position: relative;
          margin: 0;
          padding-bottom: 1.145vw;
          @media (max-width: $breakpoint-md) {
            padding-bottom: 24px;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            background: $red;
            width: 3.494vw;
            height: 0.361vw;
            border-radius: 0.181vw;
            left: 0;
            @media (max-width: $breakpoint-md) {
              border-radius: 3px;
              width: 40px;
              height: 4px;
              left: 50%;
              transform: translateX(-50%);
            }
            @media (min-width: $breakpoint-lg) {
              border-radius: 3px;
              width: 58px;
              height: 6px;
            }
          }
        }
        .text {
          margin-top: 1.807vw;
          @media (max-width: $breakpoint-md) {
            margin-top: 25px;
          }
        }
        .button {
          padding: 1.205vw 3.193vw;
          @media (max-width: $breakpoint-md) {
            padding: 20px 53px;
          }
          @media (min-width: $breakpoint-lg) {
            padding: 20px 53px;
          }
        }
      }
    }
  }
}
