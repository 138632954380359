@import '../variables.scss';

.videoStageWrapper {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 20px;
  scroll-margin-top: 75px;
  .videoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
  .imageWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    z-index: 1;
    overflow: hidden;
    max-height: 100%;
    img {
      width: 100%;
      min-height: 23.916vw;
      object-fit: fill;
      transform: scaleX(1.3);
      max-height: 100%;
      @media (max-width: $breakpoint-md) {
        max-height: 530px;
        object-fit: fill;
        transform: scaleX(1.2);
        min-height: 400px;
      }
    }
  }
  .contentWrapper {
    position: absolute;
    width: 85%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 1;
    text-transform: uppercase;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      text-align: center;
    }
  }
  &.default {
    .contentWrapper {
      p {
        font-size: 1.867vw;
        line-height: 3.494vw;
        margin: 0;
        @media (max-width: $breakpoint-md) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      h1 {
        margin: 0;
        font-style: italic;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 36px;
        }
      }
    }
  }
  &.secondDefault {
    .imageWrapper {
      img {
        width: 100%;
        min-height: 7.108vw;
        transform: scaleX(1);
        @media (max-width: $breakpoint-md) {
          // max-height: 530px;
          object-fit: fill;
          transform: scaleX(1.3);
          min-height: 100px;
        }
      }
    }
    .contentWrapper {
      text-transform: initial;
      text-align: center;
      transform: translate(-50%, -15%);
      @media (max-width: $breakpoint-md) {
        width: 222px;
        transform: translate(-50%, -25%);
      }
      p {
        text-transform: uppercase;
        font-size: 1.867vw;
        line-height: 3.494vw;
        margin: 1.627vw 0 0 0;
        @media (max-width: $breakpoint-md) {
          font-size: 20px;
          line-height: 27px;
          margin: 80px 0 0 0;
        }
      }
      h1 {
        margin: 0;
        font-style: italic;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 36px;
        }
      }
    }
  }
}
